import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "axios";
import { multiStepContext } from "../StepContext";
import { Button, DatePicker, Input, Modal, theme, Select } from "antd";
import "../Styles/ImportPage.css";
import * as XLSX from "xlsx";

const { RangePicker } = DatePicker;

const ReportDelivery = () => {
    const { admin } = useContext(multiStepContext);
    const [loading, setLoading] = useState(false);
    const [disableData, setDisableData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [disableNumberPortData, setDisableNumberPortData] = useState([]);
    const [studentNumberPortData, setStudentNumberPortData] = useState([]);
    const apiURL = process.env.REACT_APP_API_URL;
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
    const [isNumberPortModelOpen, setIsNumberPortModelOpen] = useState(false);

    const [isThirdModalOpen, setIsThirdModalOpen] = useState(false);
    const [isFourthModalOpen, setIsFourthModalOpen] = useState(false);
    const [excelDateRange, setExcelDateRange] = useState(null);
    const [secondExcelDateRange, setSecondExcelDateRange] = useState(null);
    const [numberPortDateRange, setNumberPortDateRange] = useState(null);
    const [thirdExcelDateRange, setThirdExcelDateRange] = useState(null);
    const [fourthExcelDateRange, setFourthExcelDateRange] = useState(null);
    const fileInputRef = useRef(null);
    const [networks, setNetworks] = useState([]);
    const [selectedButton, setSelectedButton] = useState("disabled");
    const [packages, setPackages] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showSecondModal = () => {
        setIsSecondModalOpen(true);
    };

    const showNumberPortModal = () => {
        setIsNumberPortModelOpen(true);
    };

    const showThirdModal = () => {
        setIsThirdModalOpen(true);
    };

    const showFourthModal = () => {
        setIsFourthModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSecondCancel = () => {
        setIsSecondModalOpen(false);
    };

    const handleNumberPortCancel = () => {
        setIsNumberPortModelOpen(false)
    }

    const handleThirdCancel = () => {
        setIsThirdModalOpen(false);
    };

    const handleFourthCancel = () => {
        setIsFourthModalOpen(false);
    };

    const handleOk = () => {
        if (!excelDateRange || excelDateRange.length !== 2) {
            alert("กรุณาเลือกช่วงวันที่ก่อนดาวน์โหลดข้อมูล");
            return;
        }

        handleExcel();
        setExcelDateRange(null);
    };

    const handleSecondOk = () => {
        if (!secondExcelDateRange || secondExcelDateRange.length !== 2) {
            alert("กรุณาเลือกช่วงวันที่ก่อนดาวน์โหลดข้อมูล");
            return;
        }

        handleSecondExcel();
        setSecondExcelDateRange(null);
    };

    const handleNumberPortOk = () => {
        if (!numberPortDateRange || numberPortDateRange.length !== 2) {
            alert("กรุณาเลือกช่วงวันที่ก่อนดาวน์โหลดข้อมูล");
            return;
        }

        handleNumberPortDisabledExcel();
        setNumberPortDateRange(null);
    }

    const handleThirdOk = () => {
        if (!thirdExcelDateRange || thirdExcelDateRange.length !== 2) {
            alert("กรุณาเลือกช่วงวันที่ก่อนดาวน์โหลดข้อมูล");
            return;
        }

        handleThirdExcel();
        setIsThirdModalOpen(false);
        setThirdExcelDateRange(null);
    };

    const handleFourthOk = () => {
        if (!fourthExcelDateRange || fourthExcelDateRange.length !== 2) {
            alert("กรุณาเลือกช่วงวันที่ก่อนดาวน์โหลดข้อมูล");
            return;
        }

        handleFourthExcel();
        setFourthExcelDateRange(null);
    };

    const generateHeader = () => {
        return [
            "NO",
            "รหัสการลงทะเบียน",
            "เลขบัตรประชาชน",
            "COMP_ORDER_ID",
            "INV_NO",
            "BARCODE_NO",
            "PRODUCT_IN_BOX",
            "SHIPPER_NAME",
            "SHIPPER_ADDRESS",
            "SHIPPER_AMPHUR",
            "SHIPPER_PROVINCE",
            "SHIPPER_ZIPCODE",
            "SHIPPER_TEL",
            "RECEIVER",
            "RECEIVER_ADDRESS",
            "RECEIVER_TAMBON",
            "RECEIVER_AMPHUR",
            "RECEIVER_PROVINCE",
            "RECEIVER_ZIPCODE",
            "RECEIVER_TEL",
            "WEIGHT",
            "PRICE",
            "INSURE",
            "INSURE_PRICE"
        ];
    };

    const generateHeaderSecond = () => {
        return [
            "รหัสการลงทะเบียน",
            "เลขบัตรประชาชน",
            "ชื่อ",
            "นามสกุล",
            "เครือข่าย",
            "ขอรับซิมการ์ดใหม่",
            "หมายเลขโทรศัพท์มือถือ",
            "รหัสสาขา",
            "ชื่อสาขาไปรษณีย์",
            "ที่อยู่สาขาไปรษณีย์"
        ];
    };

    const generateHeaderNumberPort = () => {
        return [
            "รหัสการลงทะเบียน",
            "เลขบัตรประชาชน",
            "ชื่อ",
            "นามสกุล",
            "เครือข่าย",
            "ขอรับซิมการ์ดใหม่",
            "หมายเลขโทรศัพท์มือถือ",
        ];
    };

    const generateHeaderThird = () => {
        return [
            "รหัสการลงทะเบียน",
            "เลขบัตรประชาชน",
            "ชื่อ",
            "นามสกุล",
            "เครือข่าย",
            "ขอรับซิมการ์ดใหม่",
            "หมายเลขโทรศัพท์มือถือ",
            "ชื่อศูนย์บริการคนคนพิการประจำจังหวัด",
        ];
    };

    const generateHeaderFourth = () => {
        return [
            "รหัสการลงทะเบียน",
            "เลขบัตรประชาชน",
            "ชื่อ",
            "นามสกุล",
            "เครือข่าย",
            "ขอรับซิมการ์ดใหม่",
            "หมายเลขโทรศัพท์มือถือ",
            "รหัสโรงเรียน",
            "ชื่อโรงเรียน",
            "ตำบล",
            "อำเภอ",
            "จังหวัด",
            "รหัสไปรษณีย์",
        ];
    };

    const generateRow = (user, index) => {
        const runningNumber = (index + 1).toString();
        if (selectedButton === "disabled") {
            const userName = (user.type_id === 4 && user.guardian && user.guardian.firstname && user.guardian.lastname)
                ? user.guardian.firstname + " " + user.guardian.lastname
                : "";
            return [
                runningNumber,
                "D" + user.id,
                user.nationalId,
                "",
                "",
                "",
                userName,
                "",
                "",
                "",
                "",
                "",
                "",
                user.firstName + " " + user.lastName,
                user.disabledInfo[0].address.addressName,
                user.disabledInfo[0].address.subDistrict,
                user.disabledInfo[0].address.district,
                user.disabledInfo[0].address.province,
                user.disabledInfo[0].address.zipCode,
                user.disabledInfo[0].address.phoneNumber,
                "0",
                "0",
                "0",
                "0"
            ];
        } else if (selectedButton === "student") {
            return [
                runningNumber,
                "",
                "",
                "",
                user.studentId.p_firstname + " " + user.studentId.p_lastname,
                "",
                "",
                "",
                "",
                "",
                "",
                user.studentId.s_firstname + " " + user.studentId.s_lastname,
                user.studentInfo[0].address.addressName + " " + user.studentInfo[0].address.subDistrict,
                user.studentInfo[0].address.district,
                user.studentInfo[0].address.province,
                user.studentInfo[0].address.zipCode,
                user.studentInfo[0].address.phoneNumber,
                "0",
                "0",
                "0",
                "0"
            ];
        }
    };

    const generateRowSecond = (user) => {
        if (selectedButton === "disabled") {
            return [
                "D" + user.id,
                user.nationalId,
                user.firstName,
                user.lastName,
                user.disabledInfo[0].operators.company_name,
                user.purpose,
                user.disabledInfo[0].phoneNumber ? user.disabledInfo[0].phoneNumber : "",
                user.disabledInfo[0].branch_id,
                user.disabledInfo[0].branchs.name,
                user.disabledInfo[0].branchs.fullAddress
            ];
        } else if (selectedButton === "student") {
            return [
                "S" + user.id,
                user.studentId.s_national_id,
                user.studentId.s_firstname,
                user.studentId.s_lastname,
                user.studentInfo[0].operators.company_name,
                user.purpose,
                user.studentInfo[0].phoneNumber ? user.studentInfo[0].phoneNumber : "",
                user.studentInfo[0].branch_id,
                user.studentInfo[0].branchs.name,
                user.studentInfo[0].branchs.fullAddress
            ];
        }
    };

    const generateRowNumberPort = (user) => {
        if (selectedButton === "disabled") {
            return [
                "D" + user.id,
                user.nationalId,
                user.firstName,
                user.lastName,
                user.disabledInfo[0].operators.company_name,
                user.purpose,
                user.disabledInfo[0].phoneNumber ? user.disabledInfo[0].phoneNumber : "",
            ];
        } else if (selectedButton === "student") {
            return [
                "S" + user.id,
                user.studentId.s_national_id,
                user.studentId.s_firstname,
                user.studentId.s_lastname,
                user.studentInfo[0].operators.company_name,
                user.purpose,
                user.studentInfo[0].phoneNumber ? user.studentInfo[0].phoneNumber : "",
            ];
        }
    };

    const generateRowThird = (user) => {
        if (selectedButton === "disabled") {
            return [
                "D" + user.id,
                user.nationalId,
                user.firstName,
                user.lastName,
                user.disabledInfo[0].operators.company_name,
                user.purpose,
                user.disabledInfo[0].phoneNumber ? user.disabledInfo[0].phoneNumber : "",
                "ศูนย์บริการคนพิการจังหวัด " + user.disabledInfo[0].province.name
            ];
        }
    };

    const generateRowFourth = (user) => {
        if (selectedButton === "student") {
            return [
                "S" + user.id,
                user.studentId.s_national_id,
                user.studentId.s_firstname,
                user.studentId.s_lastname,
                user.studentInfo[0].operators.company_name,
                user.purpose,
                user.studentInfo[0].phoneNumber ? user.studentInfo[0].phoneNumber : "",
                user.studentId.school_id,
                user.studentId.school_name,
                user.studentId.school_subDistrict,
                user.studentId.school_district,
                user.studentId.school_province,
                user.studentId.school_zipCode
            ];
        }
    };

    const handleExcel = async () => {
        let filteredData;
        let excelName;
        let columnsToFormat = ["P", "Q"];
        const [startDate, endDate] = excelDateRange;

        try {
            setIsDownloading(true)
            if (selectedButton === "disabled") {
                excelName = "คนพิการ";
                filteredData = disableData.filter(user => {
                    const createDate = new Date(user.disabledInfo[0].createDate);
                    return createDate >= startDate && createDate <= endDate && user.disabledInfo[0].address;
                });
            } else if (selectedButton === "student") {
                excelName = "นักเรียน";
                filteredData = studentData.filter(user => {
                    const createDate = new Date(user.studentInfo[0].createDate);
                    return createDate >= startDate && createDate <= endDate && user.studentInfo[0].address;
                });
            }

            const ws1Data = [generateHeader()];
            filteredData.forEach((user, index) => {
                const row = generateRow(user, index);
                ws1Data.push(row);
            });

            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
            const w1DataLength = ws1Data.length;
            XLSX.utils.book_append_sheet(wb, ws1, "Data");
            var fmt = "@";
            for (let i = 2; i <= w1DataLength; i++) {
                columnsToFormat.forEach((col) => {
                    if (wb.Sheets["Data"][`${col}${i}`]) {
                        wb.Sheets["Data"][`${col}${i}`].z = fmt;
                    }
                });
            }
            XLSX.writeFile(
                wb,
                `รายงานข้อมูลการจัดส่ง${excelName}.xlsx`
            );
        } catch (error) {
            console.error("Error editing user:", error);
        } finally {
            setIsDownloading(false);
            setIsModalOpen(false);
        }
    };

    const handleNumberPortDisabledExcel = () => {
        let filteredData;
        let excelName;
        let columnsToFormat = ["G"];
        const [startDate, endDate] = numberPortDateRange;

        try {
            setIsDownloading(true)
            if (selectedButton === "disabled") {
                excelName = "คนพิการ";
                filteredData = disableNumberPortData.filter(user => {
                    const createDate = new Date(user.disabledInfo[0].createDate);
                    return createDate >= startDate && createDate <= endDate;
                });
            } else if (selectedButton === "student") {
                excelName = "นักเรียน";
                filteredData = studentNumberPortData.filter(user => {
                    const createDate = new Date(user.studentInfo[0].createDate);
                    return createDate >= startDate && createDate <= endDate;
                });
            }

            console.log(filteredData)

            const ws1Data = [generateHeaderNumberPort()];
            filteredData.forEach((user) => {
                const row = generateRowNumberPort(user);
                ws1Data.push(row);
            });

            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
            const w1DataLength = ws1Data.length;
            XLSX.utils.book_append_sheet(wb, ws1, "Data");
            var fmt = "@";
            for (let i = 2; i <= w1DataLength; i++) {
                columnsToFormat.forEach((col) => {
                    if (wb.Sheets["Data"][`${col}${i}`]) {
                        wb.Sheets["Data"][`${col}${i}`].z = fmt;
                    }
                });
            }
            XLSX.writeFile(
                wb,
                `รายงานข้อมูลผู้ลงทะเบียนที่ใช้หมายเลขเดิม-${excelName}.xlsx`
            );
        } catch (error) {
            console.error("Error editing user:", error);
        } finally {
            setIsDownloading(false);
            setIsNumberPortModelOpen(false);
        }
    }

    const handleSecondExcel = () => {
        let filteredData;
        let excelName;
        let columnsToFormat = ["G", "H"];
        const [startDate, endDate] = secondExcelDateRange;

        try {
            setIsDownloading(true)
            if (selectedButton === "disabled") {
                excelName = "คนพิการ";
                filteredData = disableData.filter(user => {
                    const createDate = new Date(user.disabledInfo[0].createDate);
                    return createDate >= startDate && createDate <= endDate && user.disabledInfo[0].branchs;
                });
            } else if (selectedButton === "student") {
                excelName = "นักเรียน";
                filteredData = studentData.filter(user => {
                    const createDate = new Date(user.studentInfo[0].createDate);
                    return createDate >= startDate && createDate <= endDate && user.studentInfo[0].branchs;
                });
            }

            const ws1Data = [generateHeaderSecond()];
            filteredData.forEach((user) => {
                const row = generateRowSecond(user);
                ws1Data.push(row);
            });

            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
            const w1DataLength = ws1Data.length;
            XLSX.utils.book_append_sheet(wb, ws1, "Data");
            var fmt = "@";
            for (let i = 2; i <= w1DataLength; i++) {
                columnsToFormat.forEach((col) => {
                    if (wb.Sheets["Data"][`${col}${i}`]) {
                        wb.Sheets["Data"][`${col}${i}`].z = fmt;
                    }
                });
            }
            XLSX.writeFile(
                wb,
                `รายงานข้อมูลการเข้ารับซิมการ์ดที่ไปรษณีย์${excelName}.xlsx`
            );
        } catch (error) {
            console.error("Error editing user:", error);
        } finally {
            setIsDownloading(false);
            setIsSecondModalOpen(false);
        }
    };

    const handleThirdExcel = () => {
        let filteredData;
        let columnsToFormat = ["G"];
        const [startDate, endDate] = thirdExcelDateRange;

        try {
            setIsDownloading(true)
            if (selectedButton === "disabled") {
                filteredData = disableData.filter(user => {
                    const createDate = new Date(user.disabledInfo[0].createDate);
                    return createDate >= startDate && createDate <= endDate && user.disabledInfo[0].province;
                });
            }

            const ws1Data = [generateHeaderThird()];
            filteredData.forEach((user) => {
                const row = generateRowThird(user);
                ws1Data.push(row);
            });

            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
            const w1DataLength = ws1Data.length;
            XLSX.utils.book_append_sheet(wb, ws1, "Data");
            var fmt = "@";
            for (let i = 2; i <= w1DataLength; i++) {
                columnsToFormat.forEach((col) => {
                    if (wb.Sheets["Data"][`${col}${i}`]) {
                        wb.Sheets["Data"][`${col}${i}`].z = fmt;
                    }
                });
            }
            XLSX.writeFile(
                wb,
                `รายงานข้อมูลการเข้ารับซิมการ์ดที่ศูนย์บริการคนพิการ.xlsx`
            );
        } catch (error) {
            console.error("Error editing user:", error);
        } finally {
            setIsDownloading(false);
            setIsModalOpen(false);
        }
    };

    const handleFourthExcel = () => {
        let filteredData;
        let excelName;
        let columnsToFormat = ["G", "H"];
        const [startDate, endDate] = fourthExcelDateRange;

        try {
            setIsDownloading(true)
            if (selectedButton === "student") {
                filteredData = studentData.filter(user => {
                    const createDate = new Date(user.studentInfo[0].createDate);
                    return createDate >= startDate && createDate <= endDate && user.studentInfo[0].school_id;
                });
            }

            const ws1Data = [generateHeaderFourth()];
            filteredData.forEach((user) => {
                const row = generateRowFourth(user);
                ws1Data.push(row);
            });

            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
            const w1DataLength = ws1Data.length;
            XLSX.utils.book_append_sheet(wb, ws1, "Data");
            var fmt = "@";
            for (let i = 2; i <= w1DataLength; i++) {
                columnsToFormat.forEach((col) => {
                    if (wb.Sheets["Data"][`${col}${i}`]) {
                        wb.Sheets["Data"][`${col}${i}`].z = fmt;
                    }
                });
            }
            XLSX.writeFile(
                wb,
                `รายงานข้อมูลการเข้ารับซิมการ์ดที่โรงเรียน.xlsx`
            );
        } catch (error) {
            console.error("Error editing user:", error);
        } finally {
            setIsDownloading(false);
            setIsFourthModalOpen(false);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response1 = await Axios.get(`${apiURL}getDeliveryData`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            setDisableData(response1.data.success.resultD);
            setStudentData(response1.data.success.resultS);

            const response2 = await Axios.get(`${apiURL}getNumberPortData`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            setDisableNumberPortData(response2.data.success.resultD);
            setStudentNumberPortData(response2.data.success.resultS);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }

    };

    useEffect(() => {
        Axios.get(`${apiURL}getOperator`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                setNetworks(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, [token]);

    const clearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    useEffect(() => {
        clearFileInput();
    }, [selectedButton]);

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        Axios.get(`${apiURL}getPackage`, { headers })
            .then((res) => {
                const transformedData = res.data.map((item) => ({
                    packageId: item.id,
                    packageDetail: item.packageDetail,
                }));
                setPackages(transformedData);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleButtonClick = (buttonId) => {
        setSelectedButton(buttonId);
    };

    return (
        <div className="import-outer">
            <div className="importContainer">
                <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    รายงานข้อมูลการจัดส่งซิมการ์ด
                </span>
            </div>
            <div className="info-search">
                <div className="type-bt">
                    <Button
                        id="disabled"
                        onClick={() => handleButtonClick("disabled")}
                        type={selectedButton === "disabled" ? "primary" : "default"}
                        danger
                        size="large"
                    >
                        คนพิการ
                    </Button>
                    <Button
                        id="student"
                        onClick={() => handleButtonClick("student")}
                        type={selectedButton === "student" ? "primary" : "default"}
                        danger
                        size="large"
                    >
                        นักเรียน
                    </Button>
                </div>
            </div>
            {selectedButton === "disabled" && (
                <>
                    <div>
                        <Button onClick={showModal} type="primary" disabled={loading} loading={loading}>ดาวน์โหลดข้อมูลที่อยู่การจัดส่งซิมการ์ด</Button>
                        <Modal
                            title="ข้อมูลที่อยู่การจัดส่งซิมการ์ด"
                            open={isModalOpen}
                            onOk={handleOk}
                            okText={isDownloading ? "Downloading..." : "OK"}
                            okButtonProps={{ disabled: isDownloading }}
                            onCancel={handleCancel}
                        >
                            <div>
                                <span style={{ marginRight: 10 }}>วันที่ข้อมูล:</span>
                                <RangePicker
                                    onChange={(dates) => {
                                        if (dates && dates.length === 2) {
                                            const startDate = dates[0].startOf('day'); // 00:00:00
                                            const endDate = dates[1].endOf('day'); // 23:59:59
                                            setExcelDateRange([startDate, endDate]);
                                        } else {
                                            setExcelDateRange(null);
                                        }
                                    }}
                                    value={excelDateRange}
                                />
                            </div>
                        </Modal>
                    </div>
                </>
            )}
            <div>
                <Button onClick={showSecondModal} type="primary" disabled={loading} loading={loading}>ดาวน์โหลดข้อมูลการเข้ารับซิมการ์ดที่ไปรษณีย์</Button>
                <Modal
                    title="ข้อมูลการเข้ารับซิมการ์ดที่ไปรษณีย์"
                    open={isSecondModalOpen}
                    onOk={handleSecondOk}
                    okText={isDownloading ? "Downloading..." : "OK"}
                    okButtonProps={{ disabled: isDownloading }}
                    onCancel={handleSecondCancel}
                >
                    <div>
                        <span style={{ marginRight: 10 }}>วันที่ข้อมูล:</span>
                        <RangePicker
                            onChange={(dates) => {
                                if (dates && dates.length === 2) {
                                    const startDate = dates[0].startOf('day'); // 00:00:00
                                    const endDate = dates[1].endOf('day'); // 23:59:59
                                    setSecondExcelDateRange([startDate, endDate]);
                                } else {
                                    setSecondExcelDateRange(null);
                                }
                            }}
                            value={secondExcelDateRange}
                        />
                    </div>
                </Modal>
            </div>
            {/* {selectedButton === "disabled" && (
                <>
                    <div>
                        <Button onClick={showThirdModal} type="primary">ดาวน์โหลดข้อมูลการเข้ารับซิมการ์ดที่ศูนย์บริการคนพิการ</Button>
                        <Modal
                            title="ข้อมูลการเข้ารับซิมการ์ดที่ศูนย์บริการคนพิการ"
                            open={isThirdModalOpen}
                            onOk={handleThirdOk}
                            onCancel={handleThirdCancel}
                        >
                            <div>
                                <span style={{ marginRight: 10 }}>วันที่ข้อมูล:</span>
                                <RangePicker
                                    onChange={(dates) => {
                                        if (dates && dates.length === 2) {
                                            const startDate = dates[0].startOf('day'); // 00:00:00
                                            const endDate = dates[1].endOf('day'); // 23:59:59
                                            setThirdExcelDateRange([startDate, endDate]);
                                        } else {
                                            setThirdExcelDateRange(null);
                                        }
                                    }}
                                    value={thirdExcelDateRange}
                                />
                            </div>
                        </Modal>
                    </div>
                </>
            )} */}
            {selectedButton === "student" && (
                <>
                    <div>
                        <Button onClick={showFourthModal} type="primary" disabled={loading} loading={loading}>ดาวน์โหลดข้อมูลการเข้ารับซิมการ์ดที่โรงเรียน</Button>
                        <Modal
                            title="ข้อมูลการเข้ารับซิมการ์ดที่โรงเรียน"
                            open={isFourthModalOpen}
                            onOk={handleFourthOk}
                            okText={isDownloading ? "Downloading..." : "OK"}
                            okButtonProps={{ disabled: isDownloading }}
                            onCancel={handleFourthCancel}
                        >
                            <div>
                                <span style={{ marginRight: 10 }}>วันที่ข้อมูล:</span>
                                <RangePicker
                                    onChange={(dates) => {
                                        if (dates && dates.length === 2) {
                                            const startDate = dates[0].startOf('day'); // 00:00:00
                                            const endDate = dates[1].endOf('day'); // 23:59:59
                                            setFourthExcelDateRange([startDate, endDate]);
                                        } else {
                                            setFourthExcelDateRange(null);
                                        }
                                    }}
                                    value={fourthExcelDateRange}
                                />
                            </div>
                        </Modal>
                    </div>
                </>
            )}
            <hr /><hr />
            <div>
                ข้อมูลผู้ที่ใช้หมายเลขโทรศัพท์เดิม : <br /><br />
                <Button onClick={showNumberPortModal} type="primary" disabled={loading} loading={loading}>ดาวน์โหลดข้อมูลผู้ที่ใช้หมายเลขโทรศัพท์เดิม</Button>
                <Modal
                    title="ข้อมูลผู้ที่ใช้หมายเลขโทรศัพท์เดิม"
                    open={isNumberPortModelOpen}
                    onOk={handleNumberPortOk}
                    okText={isDownloading ? "Downloading..." : "OK"}
                    okButtonProps={{ disabled: isDownloading }}
                    onCancel={handleNumberPortCancel}
                >
                    <div>
                        <span style={{ marginRight: 10 }}>วันที่ข้อมูล:</span>
                        <RangePicker
                            onChange={(dates) => {
                                if (dates && dates.length === 2) {
                                    const startDate = dates[0].startOf('day'); // 00:00:00
                                    const endDate = dates[1].endOf('day'); // 23:59:59
                                    setNumberPortDateRange([startDate, endDate]);
                                } else {
                                    setNumberPortDateRange(null);
                                }
                            }}
                            value={numberPortDateRange}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ReportDelivery;