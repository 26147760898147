import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "axios";
import { multiStepContext } from "../StepContext";
import { Button, Input, Modal, Radio } from "antd";
import {
    DownloadOutlined,
    UndoOutlined,
    EditOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import "../Styles/ImportPage.css";
import * as XLSX from "xlsx";

const ReportUsage = () => {
    const { admin } = useContext(multiStepContext);
    const [data, setData] = useState([]);
    const apiURL = process.env.REACT_APP_API_URL;
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [network, setNetwork] = useState("");
    const [publish, setPublish] = useState("");
    const fileInputRef = useRef(null);
    const [userData, setUserdata] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState("");
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedButton, setSelectedButton] = useState("disabled");
    const [disableData, setDisableData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("2024");
    const [selectedMonth, setSelectedMonth] = useState("01");
    const [isUploading, setIsUploading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownloadExcel = async () => {
        setIsDownloading(true);
        try {
            await handleExcel();
        } catch (error) {
            console.error("Download failed", error);
        } finally {
            setIsDownloading(false);
        }
    };

    const fetchDisabledData = async () => {
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        try {
            const res = await Axios.get(`${apiURL}getAllDisabled`, { headers });
            return res.data.result;
        } catch (err) {
            console.log(err);
        }
    };

    const fetchStudentData = async () => {
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        try {
            const res = await Axios.get(`${apiURL}getAllStudent`, { headers });
            return res.data.result;
        } catch (err) {
            console.log(err);
        }
    };

    const generateHeader = () => {
        if (selectedButton === "disabled") {
            return [
                "รหัสการลงทะเบียน",
                "เลขบัตรประชาชน",
                "ชื่อ",
                "นามสกุล",
                "เครือข่าย",
                "ขอรับซิมการ์ดใหม่",
                "หมายเลขโทรศัพท์มือถือ",
                "วันที่เริ่มใช้งาน",
                "วันที่สิ้นสุดใช้งาน",
                "ปริมาณการใช้งาน (MB)",
                "Invoice No"
            ];
        } else if (selectedButton === "student") {
            return [
                "รหัสการลงทะเบียน",
                "เลขบัตรประชาชน",
                "ชื่อ",
                "นามสกุล",
                "ระดับชั้น",
                "เครือข่าย",
                "ขอรับซิมการ์ดใหม่",
                "หมายเลขโทรศัพท์มือถือ",
                "วันที่เริ่มใช้งาน",
                "วันที่สิ้นสุดใช้งาน",
                "ปริมาณการใช้งาน (MB)",
                "Invoice No"
            ];
        }
    };

    const generateRow = (user) => {
        if (selectedButton === "disabled") {
            return [
                "D" + user.id,
                user.nationalId,
                user.firstName,
                user.lastName,
                user.disabledInfo[0].operators.company_name,
                user.purpose,
                user.disabledInfo[0].phoneNumber ? user.disabledInfo[0].phoneNumber : "",
                "",
                "",
                "",
                ""
            ];
        } else if (selectedButton === "student") {
            return [
                "S" + user.id,
                user.studentId.s_national_id,
                user.studentId.s_firstname,
                user.studentId.s_lastname,
                user.studentId.degrees,
                user.studentInfo[0].operators.company_name,
                user.purpose,
                user.studentInfo[0].phoneNumber ? user.studentInfo[0].phoneNumber : "",
                "",
                "",
                "",
                ""
            ];
        }
    };

    const handleExcel = async () => {
        let filteredData;
        let excelName;
        let columnsToFormat = [];

        if (selectedButton === "disabled") {
            const disableData = await fetchDisabledData()
            excelName = "ข้อมูลคนพิการ";
            columnsToFormat = ["G", "H", "I", "J", "K"];
            filteredData = disableData.filter(
                (data) =>
                    (data.status === "approve" || data.status === "confirm") &&
                    data.approve_status !== "ไม่ผ่าน"
            );
        } else if (selectedButton === "student") {
            const studentData = await fetchStudentData()
            excelName = "ข้อมูลนักเรียน";
            columnsToFormat = ["G", "I", "J", "K", "L"];
            filteredData = studentData.filter(
                (data) =>
                    (data.status === "approve" || data.status === "confirm") &&
                    data.approve_status !== "ไม่ผ่าน"
            );
        } else {
            return;
        }
        const ws1Data = [generateHeader()];
        filteredData.forEach((user) => {
            const row = generateRow(user);
            ws1Data.push(row);
        });

        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
        const w1DataLength = ws1Data.length;
        XLSX.utils.book_append_sheet(wb, ws1, "Data");
        var fmt = "@";
        for (let i = 2; i <= w1DataLength; i++) {
            columnsToFormat.forEach((col) => {
                if (wb.Sheets["Data"][`${col}${i}`]) {
                    wb.Sheets["Data"][`${col}${i}`].z = fmt; // Set format to text (@)
                }
            });
        }
        XLSX.writeFile(
            wb,
            `รายงานข้อมูลการใช้งาน${excelName}.xlsx`
        );
    };

    const fetchData = async () => {
        try {
            const response = await Axios.get(`${apiURL}upload/getAllFileUsage`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            setData(response.data.success);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        Axios.get(`${apiURL}getOperator`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                setNetworks(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {

        if (!file) {
            alert("กรุณาเลือกไฟล์");
            return;
        }

        if (admin.role !== 'operator' && !selectedNetwork) {
            alert("กรุณาเลือกเครือข่าย");
            return;
        }

        try {
            setIsUploading(true);
            let apiUrl = `${apiURL}upload/excel/dataUsage/`;

            let requestBody = {
                fileName: file.name,
                type: selectedButton,
                operatorId: selectedNetwork,
                data: userData,
                year: selectedYear,
                month: selectedMonth
            };

            if (admin && admin.role !== null) {
                if (admin.role === "operator") {
                    requestBody.operatorId = selectedNetwork || admin.operator_id;
                } else {
                    requestBody.operatorId = selectedNetwork;
                }
            } else {
            }

            if (selectedButton === "disabled") {
                apiUrl += "disabled";
            } else if (selectedButton === "student") {
                apiUrl += "student";
            }

            const response = await Axios.post(apiUrl, requestBody, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            setIsModalOpen(false);
            setFile(null);
            setNetwork("");
            setSelectedYear("2024")
            setSelectedMonth("01")
            const fileInput = document.querySelector('input[type="file"]');
            if (fileInput) {
                fileInput.value = "";
            }
            fetchData();
        } catch (error) {
            console.error("Error editing user:", error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedYear("2024")
        setSelectedMonth("01")
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const clearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    useEffect(() => {
        clearFileInput();
    }, [selectedButton]);

    const showDeleteModal = (userId) => {
        setIsDeleteModalOpen(true);
        setUserIdToDelete(userId);
    };

    const handleDownload = async (itemId) => {
        try {
            const downloadURL = `${apiURL}admin/downloadFiles/dataUsage/${itemId}`;
            const response = await Axios.get(downloadURL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            let data;
            if (selectedButton === 'student') {
                data = response.data.data.recordStudent;
            } else {
                data = response.data.data.recordDisabled;
            }

            const rows = data.map(item => {
                if (selectedButton === "student") {
                    return {
                        รหัสการลงทะเบียน: "S" + item.s_student_id,
                        เลขบัตรประชาชน: item.s_national_id,
                        ชื่อ: item.s_firstname,
                        นามสกุล: item.s_lastname,
                        ระดับชั้น: item.degree,
                        เครือข่าย: item.s_operator,
                        ขอรับซิมการ์ดใหม่: item.s_purpose,
                        หมายเลขโทรศัพท์มือถือ: item.s_phone,
                        วันที่เริ่มใช้งาน: item.startUsageDate,
                        วันที่สิ้นสุดใช้งาน: item.endUsageDate,
                        "ปริมาณการใช้งาน (MB)": item.usage,
                        "Invoice No": item.invoiceNo
                    };
                } else {
                    return {
                        รหัสการลงทะเบียน: "D" + item.d_disable_id,
                        เลขบัตรประชาชน: item.d_national_id,
                        ชื่อ: item.d_firstname,
                        นามสกุล: item.d_lastname,
                        เครือข่าย: item.d_operator,
                        ขอรับซิมการ์ดใหม่: item.d_purpose,
                        หมายเลขโทรศัพท์มือถือ: item.d_phone,
                        วันที่เริ่มใช้งาน: item.startUsageDate,
                        วันที่สิ้นสุดใช้งาน: item.endUsageDate,
                        "ปริมาณการใช้งาน (MB)": item.usage,
                        "Invoice No": item.invoiceNo
                    };
                }
            });

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(rows);

            if (selectedButton === "student") {
                const textFormatColumns = ['H', 'I', 'J', 'K', 'L'];
                textFormatColumns.forEach(col => {
                    rows.forEach((item, index) => {
                        worksheet[col + (index + 2)].z = '@';
                    });
                });
            } else {
                const textFormatColumns = ['G', 'H', 'I', 'J', 'K'];
                textFormatColumns.forEach(col => {
                    rows.forEach((item, index) => {
                        worksheet[col + (index + 2)].z = '@';
                    });
                });
            }


            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const FileName = (selectedButton === "disabled" ? "รายงานข้อมูลการใช้งานข้อมูลคนพิการ" : "รายงานข้อมูลการใช้งานข้อมูลนักเรียน")
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${FileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            fetchData();
        } catch (error) {
            console.error("Error downloading data:", error);
        }
    };


    const handleDeleteOk = async () => {
        try {
            const deleteURL = `${apiURL}deleteImportFileUsage/${userIdToDelete}`;
            const response = await Axios.delete(deleteURL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            setIsDeleteModalOpen(false);
            fetchData();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    };

    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        Axios.get(`${apiURL}getPackage`, { headers })
            .then((res) => {
                const transformedData = res.data.map((item) => ({
                    packageId: item.id,
                    packageDetail: item.packageDetail,
                }));
                setPackages(transformedData);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const excelColumnHeaders = sheetData[0];

            const expectedColumnHeaders =
                selectedButton === "disabled"
                    ? [
                        "รหัสการลงทะเบียน",
                        "เลขบัตรประชาชน",
                        "ชื่อ",
                        "นามสกุล",
                        "เครือข่าย",
                        "ขอรับซิมการ์ดใหม่",
                        "หมายเลขโทรศัพท์มือถือ",
                        "วันที่เริ่มใช้งาน",
                        "วันที่สิ้นสุดใช้งาน",
                        "ปริมาณการใช้งาน (MB)",
                        "Invoice No"
                    ]
                    : [
                        "รหัสการลงทะเบียน",
                        "เลขบัตรประชาชน",
                        "ชื่อ",
                        "นามสกุล",
                        "ระดับชั้น",
                        "เครือข่าย",
                        "ขอรับซิมการ์ดใหม่",
                        "หมายเลขโทรศัพท์มือถือ",
                        "วันที่เริ่มใช้งาน",
                        "วันที่สิ้นสุดใช้งาน",
                        "ปริมาณการใช้งาน (MB)",
                        "Invoice No"
                    ];

            const columnsMatch =
                JSON.stringify(excelColumnHeaders) ===
                JSON.stringify(expectedColumnHeaders);

            if (columnsMatch) {
                alert("รูปแบบไฟล์ถูกต้อง");
                const formData = new FormData();
                formData.append("file", file);
                formData.append("fileName", file.name);
                formData.append("selectedButton", selectedButton);

                const sheetDataWithoutHeader = sheetData.slice(1);
                const formattedData = sheetDataWithoutHeader.map((row, index) => {
                    if (selectedButton === "disabled") {
                        return {
                            Client: index,
                            citizenId: row[1],
                            firstname: row[2],
                            lastname: row[3],
                            service: row[4],
                            purpose: row[5],
                            phoneNumber: row[6],
                            startUsageDate: row[7],
                            endUsageDate: row[8],
                            dataUsage: row[9],
                            invoiceNo: row[10]
                        };
                    } else if (selectedButton === "student") {
                        return {
                            Client: index,
                            citizenId: row[1],
                            firstname: row[2],
                            lastname: row[3],
                            degree: row[4],
                            service: row[5],
                            purpose: row[6],
                            phoneNumber: row[7],
                            startUsageDate: row[8],
                            endUsageDate: row[9],
                            dataUsage: row[10],
                            invoiceNo: row[11]
                        };
                    }
                });
                setUserdata(formattedData);
            } else {
                alert("รูปแบบไฟล์หรือขนาดไฟล์ไม่ถูกต้อง");
                clearFileInput();
                setFile(null);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const [searchValue, setSearchValue] = useState("");

    const filteredData = data.filter(
        (item) =>
            item.file_name &&
            item.file_name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleNetworkChange = (selectedNetworkId) => {
        setSelectedNetwork(selectedNetworkId);
    };

    const Table = () => {
        const formatThaiDate = (month, year) => {
            const date = new Date(`${year}-${month.padStart(2, '0')}-01`);
            return date.toLocaleDateString('th-TH', { year: 'numeric', month: 'long' });
        };

        let filteredDataByType = [];

        if (selectedButton === "disabled") {
            filteredDataByType = data.filter((item) => item.uploadType === "disabled");
        } else if (selectedButton === "student") {
            filteredDataByType = data.filter((item) => item.uploadType === "student");
        }

        // If admin.role is not operator, render all data without filtering
        if (admin.role !== "operator") {
            return (
                <div className="import-scroll">
                    <div className="container">
                        <div className="inner-con">
                            <style>
                                {`
                                    * {
                                        box-sizing: border-box;
                                    }
    
                                    .table-container {
                                        border-collapse: collapse;
                                        width: 100%;
                                        border: 1px solid black;
                                        margin-top: 20px;
                                    }
    
                                    .table-container th {
                                        padding: 15px;
                                        text-align: center;
                                        vertical-align: middle;
                                        background-color: rgb(239, 147, 108);
                                    }
    
                                    .table-container td {
                                        padding: 15px;
                                        text-align: center;
                                        vertical-align: middle;
                                    }
    
                                    .table-container tr:hover {
                                        background-color: #f5f5f5;
                                    }
    
                                    .inner-con {
                                        width: 100%;
                                        display: flex;
                                    }
                                `}
                            </style>
                            <table className="table-container">
                                <thead>
                                    <tr>
                                        <th>รายการ</th>
                                        <th>เครือข่าย</th>
                                        <th>ผู้อัปโหลด</th>
                                        <th>อัปโหลดเมื่อ</th>
                                        <th>ข้อมูลประจำเดือน</th>
                                        <th>จัดการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredDataByType.map((item) => {
                                        const record = item.recordDisabled.length > 0 ? item.recordDisabled[0] : item.recordStudent[0];
                                        const monthYear = record ? formatThaiDate(record.month, record.year) : "-";

                                        return (
                                            <tr key={item.id}>
                                                <td>{item.file_name}</td>
                                                <td>{item.operators.company_name}</td>
                                                <td>{item.admin.username}</td>
                                                <td>{new Date(item.createDate).toLocaleString()}</td>
                                                <td>{monthYear}</td>
                                                <td className="import-bt">
                                                    <Button
                                                        icon={<DownloadOutlined />}
                                                        onClick={() => handleDownload(item.id)}
                                                    >
                                                        ดาวน์โหลด
                                                    </Button>
                                                    {admin.role === "operator" && (
                                                        <>
                                                            <Button
                                                                icon={<DeleteOutlined />}
                                                                onClick={() => showDeleteModal(item.id)}
                                                            >
                                                                ลบไฟล์
                                                            </Button>
                                                            <Modal
                                                                title="ลบข้อมูล"
                                                                open={isDeleteModalOpen}
                                                                onOk={handleDeleteOk}
                                                                onCancel={handleDeleteCancel}
                                                            ></Modal>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else {
            // If admin.role is operator, filter the data based on company name
            filteredDataByType = filteredDataByType.filter(
                (item) => item.admin && item.operators.id === admin.operator_id
            );

            return (
                <div className="import-scroll">
                    <div className="container">
                        <div className="inner-con">
                            <style>
                                {`
                                    * {
                                        box-sizing: border-box;
                                    }
    
                                    .table-container {
                                        border-collapse: collapse;
                                        width: 100%;
                                        border: 1px solid black;
                                        margin-top: 20px;
                                    }
    
                                    .table-container th {
                                        padding: 15px;
                                        text-align: center;
                                        vertical-align: middle;
                                        background-color: rgb(239, 147, 108);
                                    }
    
                                    .table-container td {
                                        padding: 15px;
                                        text-align: center;
                                        vertical-align: middle;
                                    }
    
                                    .table-container tr:hover {
                                        background-color: #f5f5f5;
                                    }
    
                                    .inner-con {
                                        width: 100%;
                                        display: flex;
                                    }
                                `}
                            </style>
                            <table className="table-container">
                                <thead>
                                    <tr>
                                        <th>รายการ</th>
                                        <th>เครือข่าย</th>
                                        <th>ผู้อัปโหลด</th>
                                        <th>อัปโหลดเมื่อ</th>
                                        <th>ข้อมูลประจำเดือน</th>
                                        <th>จัดการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredDataByType.map((item) => {
                                        const record = item.recordDisabled.length > 0 ? item.recordDisabled[0] : item.recordStudent[0];
                                        const monthYear = record ? formatThaiDate(record.month, record.year) : "-";

                                        return (
                                            <tr key={item.id}>
                                                <td>{item.file_name}</td>
                                                <td>{item.operators.company_name}</td>
                                                <td>{item.admin.username}</td>
                                                <td>{new Date(item.createDate).toLocaleString()}</td>
                                                <td>{monthYear}</td>
                                                <td className="import-bt">
                                                    <Button
                                                        icon={<DownloadOutlined />}
                                                        onClick={() => handleDownload(item.id)}
                                                    >
                                                        ดาวน์โหลด
                                                    </Button>
                                                    {admin.role === "operator" && (
                                                        <>
                                                            <Button
                                                                icon={<DeleteOutlined />}
                                                                onClick={() => showDeleteModal(item.id)}
                                                            >
                                                                ลบไฟล์
                                                            </Button>
                                                            <Modal
                                                                title="ลบข้อมูล"
                                                                open={isDeleteModalOpen}
                                                                onOk={handleDeleteOk}
                                                                onCancel={handleDeleteCancel}
                                                            ></Modal>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const handleButtonClick = (buttonId) => {
        setSelectedButton(buttonId);
    };

    return (
        <div className="import-outer">
            <div className="importContainer">
                <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    รายงานข้อมูลการใช้งาน
                </span>
                <Button type="primary" onClick={handleDownloadExcel} disabled={isDownloading}>
                    {isDownloading ? 'กำลังดาวน์โหลด...' : 'ดาวน์โหลด Template'}
                </Button>
            </div>
            <div className="info-search">
                <div className="type-bt">
                    <Button
                        id="disabled"
                        onClick={() => handleButtonClick("disabled")}
                        type={selectedButton === "disabled" ? "primary" : "default"}
                        danger
                        size="large"
                    >
                        คนพิการ
                    </Button>
                    <Button
                        id="student"
                        onClick={() => handleButtonClick("student")}
                        type={selectedButton === "student" ? "primary" : "default"}
                        danger
                        size="large"
                    >
                        นักเรียน
                    </Button>
                </div>
                <Button type="primary" onClick={showModal}>
                    Import ข้อมูลการใช้งาน
                </Button>
                <Modal
                    title="Import ไฟล์เข้าสู่ระบบ"
                    open={isModalOpen}
                    onOk={handleOk}
                    okText={isUploading ? "Uploading..." : "OK"}
                    okButtonProps={{ disabled: isUploading }}
                    onCancel={handleCancel}
                >
                    <div className="import-file">
                        <p>ไฟล์</p>
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="import-service">
                        <p>เครือข่าย</p>
                        {admin.role === "operator" ? (
                            <div>{admin.organization}</div>
                        ) : (
                            <select
                                value={selectedNetwork}
                                onChange={(e) => handleNetworkChange(e.target.value)}
                            >
                                <option disabled value="">
                                    กรุณาเลือกเครือข่าย
                                </option>
                                {networks.map((network) => (
                                    <option key={network.id} value={network.id}>
                                        {network.company_name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                    <div className="import-service">
                        <p>ปี</p>
                        <select onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                            <option disabled value="">
                                กรุณาเลือกปี
                            </option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                        <p>เดือน</p>
                        <select onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                            <option disabled value="">
                                กรุณาเลือกเดือน
                            </option>
                            <option value="01">มกราคม</option>
                            <option value="02">กุมภาพันธ์</option>
                            <option value="03">มีนาคม</option>
                            <option value="04">เมษายน</option>
                            <option value="05">พฤษภาคม</option>
                            <option value="06">มิถุนายน</option>
                            <option value="07">กรกฎาคม</option>
                            <option value="08">สิงหาคม</option>
                            <option value="09">กันยายน</option>
                            <option value="10">ตุลาคม</option>
                            <option value="11">พฤศจิกายน</option>
                            <option value="12">ธันวาคม</option>
                        </select>
                    </div>
                </Modal>
            </div>
            {Table()}
        </div>
    );
};

export default ReportUsage;