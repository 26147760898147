import React, { useState, useEffect, useRef, useContext } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Modal, theme, Select } from "antd";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { useReactToPrint } from "react-to-print";
import "../Styles/ManagePage.css";
import Axios from "axios";
import { multiStepContext } from "../StepContext";
const moment = require("moment");
require("moment/locale/th");
moment.locale("th");
const apiURL = process.env.REACT_APP_API_URL;
const { Search } = Input;
const { RangePicker } = DatePicker;
const ManagePage = () => {
  const [currentData, setCurrentData] = useState([]);
  const [selectedButton, setSelectedButton] = useState("disable");
  const [dateRange, setDateRange] = useState(null);
  const [excelDateRange, setExcelDateRange] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const { token: { colorBgContainer, borderRadiusLG }, } = theme.useToken();
  const [disableData, setDisableData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all')
  const [passStatus, setPassStatus] = useState('all')
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [data, setData] = useState([]);
  const [currentPageDisabled, setCurrentPageDisabled] = useState(1);
  const [currentPageStudent, setCurrentPageStudent] = useState(1);
  const [totalPagesDisabled, setTotalPagesDisabled] = useState(1);
  const [totalPagesStudent, setTotalPagesStudent] = useState(1);
  const [prevSearchValue, setPrevSearchValue] = useState("");
  const [prevDateRange, setPrevDateRange] = useState(null);
  const pageSize = 10;
  const [isDownloading, setIsDownloading] = useState(false);

  // test token
  const { admin } = useContext(multiStepContext);
  const token = localStorage.getItem("token");

  const getDisabledData = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const res = await Axios.get(`${apiURL}getAllDisabled`, { headers });
      return res.data.result;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  const getStudentData = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const res = await Axios.get(`${apiURL}getAllStudent`, { headers });
      return res.data.result;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleExcel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setFilterStatusChange = (value) => {
    setFilterStatus(value)
  }

  const setPassStatusChange = (value) => {
    setPassStatus(value)
  }

  const handlePublishOk = () => {
    setIsPublishModalOpen(false);
    console.log(selectedCheckboxes);
    if (selectedCheckboxes.length === 0) {
      return;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    if (selectedButton === "disable") {
      const dataToSend = selectedCheckboxes.map((checkbox) => ({
        id: checkbox.id,
        type: "disable",
      }));
      Axios.put(`${apiURL}public/disabled`, dataToSend, { headers })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else if (selectedButton === "student") {
      const dataToSend = selectedCheckboxes.map((checkbox) => ({
        id: checkbox.id,
        type: "student",
      }));
      console.log("data to send", dataToSend);
      Axios.put(`${apiURL}public/student`, dataToSend, { headers })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    setIsChecked(false);
    setSelectedCheckboxes([]);
  };

  const fetchData = async () => {
    try {
      if (searchValue !== prevSearchValue || dateRange !== prevDateRange) {
        if (selectedButton === "disable") {
          setCurrentPageDisabled(1);
        } else {
          setCurrentPageStudent(1);
        }
        setPrevSearchValue(searchValue);
        setPrevDateRange(dateRange);
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const queryParams = {
        page: selectedButton === "disable" ? currentPageDisabled : currentPageStudent,
        pageSize,
        search: searchValue || "",
        startDate: dateRange?.[0]?.format("YYYY-MM-DD") || "",
        endDate: dateRange?.[1]?.format("YYYY-MM-DD") || "",
      };

      const apiEndpoint =
        selectedButton === "disable"
          ? "getAllDisabledPagination"
          : "getAllStudentPagination";

      const response = await Axios.post(`${apiURL}${apiEndpoint}`, queryParams, { headers });
      setData(response.data.result.items || []);

      if (selectedButton === "disable") {
        setTotalPagesDisabled(Math.ceil(response.data.result.total / pageSize));
      } else {
        setTotalPagesStudent(Math.ceil(response.data.result.total / pageSize));
      }

      if (response.data.result.items.length === 0) {
        setNoResultsFound(true);
      } else {
        setNoResultsFound(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedButton, currentPageDisabled, currentPageStudent, searchValue, dateRange]);

  const handlePageInput = (e) => {
    const value = Math.max(1, Math.min(selectedButton === "disable" ? totalPagesDisabled : totalPagesStudent, parseInt(e.target.value) || 1)); // Ensure within valid range
    if (selectedButton === "disable") {
      setCurrentPageDisabled(value);
    } else {
      setCurrentPageStudent(value);
    }
  };

  //Table

  const renderTable = () => (
    <div>
      <div className="inner-con">
        <table className="table-with-gap">
          <thead>
            <tr>
              <th>เลขบัตรประชาชน</th>
              <th>ชื่อ</th>
              <th>นามสกุล</th>
              <th>หมายเลขโทรศัพท์มือถือ</th>
              {selectedButton === "student" && <th>ระดับชั้น</th>}
              <th>เครือข่าย</th>
              <th>ขอรับซิมการ์ดใหม่</th>
              <th>สถานะ</th>
              <th>วันที่ได้ข้อมูล</th>
              <th>ผลการอนุมัติ</th>
            </tr>
          </thead>
          <tbody>
            {noResultsFound ? (
              <tr>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  No results found
                </td>
              </tr>
            ) : (
              data.map((user, index) => (
                <tr key={index}>
                  <td>{user.nationalId || user.studentId?.s_national_id}</td>
                  <td>{user.studentId?.s_firstname || user.firstName}</td>
                  <td>{user.studentId?.s_lastname || user.lastName}</td>
                  <td>
                    {user.disabledInfo?.[0]?.phoneNumber || user.studentInfo?.[0]?.phoneNumber}
                  </td>
                  {selectedButton === "student" && (
                    <td>{user.studentId?.degrees}</td>
                  )}
                  <td>
                    {user.disabledInfo?.[0]?.operators?.company_name ||
                      user.studentInfo?.[0]?.operators?.company_name}
                  </td>
                  <td>{user.purpose || ""}</td>
                  <td>{user.status || ""}</td>
                  <td>
                    {(() => {
                      const dateStr =
                        user.disabledInfo?.[0]?.createDate ||
                        user.studentInfo?.[0]?.createDate;
                      if (!dateStr) return "";
                      return moment(dateStr).format("DD/MM/YYYY");
                    })()}
                  </td>
                  <td>{user.approve_status || ""}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {!noResultsFound && (
        <div className="pagination" style={{ gap: '5px', display: 'flex', padding: '10px', justifyContent: 'end' }}>
          <button
            onClick={() => {
              if (selectedButton === "disable") {
                setCurrentPageDisabled((prev) => Math.max(prev - 1, 1));
              } else {
                setCurrentPageStudent((prev) => Math.max(prev - 1, 1));
              }
            }}
            disabled={selectedButton === "disable" ? currentPageDisabled === 1 : currentPageStudent === 1}
          >
            Previous
          </button>

          <span>
            Page {selectedButton === "disable" ? currentPageDisabled : currentPageStudent} of{" "}
            {selectedButton === "disable" ? totalPagesDisabled : totalPagesStudent}
          </span>

          <button
            onClick={() => {
              if (selectedButton === "disable") {
                setCurrentPageDisabled((prev) => Math.min(prev + 1, totalPagesDisabled));
              } else {
                setCurrentPageStudent((prev) => Math.min(prev + 1, totalPagesStudent));
              }
            }}
            disabled={
              selectedButton === "disable"
                ? currentPageDisabled === totalPagesDisabled
                : currentPageStudent === totalPagesStudent
            }
          >
            Next
          </button>

          <input
            type="number"
            min="1"
            max={selectedButton === "disable" ? totalPagesDisabled : totalPagesStudent}
            value={selectedButton === "disable" ? currentPageDisabled : currentPageStudent}
            onChange={handlePageInput}
            style={{ width: "50px", maxWidth: '200px', marginLeft: "5px" }}
          />
        </div>
      )}
    </div>
  );

  //SET button color
  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
    setCurrentData(buttonId === "disable" ? disableData : studentData);
    setSelectedCheckboxes([]);
  };
  const [sheetData, setSheetData] = useState(null);

  useEffect(() => {
    if (selectedButton === "disable") {
      setSheetData(currentData);
    } else if (selectedButton === "student") {
      setSheetData(currentData);
    }
  }, [selectedButton]);

  const generateHeader = () => {
    if (selectedButton === "disable") {
      return [
        "รหัสการลงทะเบียน",
        "เลขบัตรประชาชน",
        "ชื่อ",
        "นามสกุล",
        "เครือข่าย",
        "ขอรับซิมการ์ดใหม่",
        "หมายเลขโทรศัพท์มือถือ",
        "วันที่ได้ข้อมูล",
        "สถานะ",
        "ผลการอนุมัติ (ผ่าน/ไม่ผ่าน)",
        "เหตุผล (กรณีไม่ผ่าน)",
      ];
    } else if (selectedButton === "student") {
      return [
        "รหัสการลงทะเบียน",
        "เลขบัตรประชาชน",
        "ชื่อ",
        "นามสกุล",
        "ระดับชั้น",
        "เครือข่าย",
        "ขอรับซิมการ์ดใหม่",
        "หมายเลขโทรศัพท์มือถือ",
        "วันที่ได้ข้อมูล",
        "สถานะ",
        "ผลการอนุมัติ (ผ่าน/ไม่ผ่าน)",
        "เหตุผล (กรณีไม่ผ่าน)",
      ];
    }
  };

  const generateRow = (user) => {
    if (selectedButton === "disable") {
      return [
        "D" + user.id,
        user.nationalId,
        user.firstName,
        user.lastName,
        user.disabledInfo[0].operators.company_name,
        user.purpose,
        user.disabledInfo[0].phoneNumber ? user.disabledInfo[0].phoneNumber : "",
        user.disabledInfo[0].createDate
          ? (() => {
            const createDate = user.disabledInfo[0].createDate;
            const dateWithoutTime = createDate.split("T")[0];
            const [year, month, day] = dateWithoutTime.split("-");
            return `${day}/${month}/${year}`;
          })()
          : "",
        user.status,
        user.approve_status ? user.approve_status : "",
        user.approve_reason ? user.approve_reason : "",
      ];
    } else if (selectedButton === "student") {
      return [
        "S" + user.id,
        user.studentId.s_national_id,
        user.studentId.s_firstname,
        user.studentId.s_lastname,
        user.studentId.degrees,
        user.studentInfo[0].operators.company_name,
        user.purpose,
        user.studentInfo[0].phoneNumber ? user.studentInfo[0].phoneNumber : "",
        user.studentInfo[0].createDate
          ? (() => {
            const createDate = user.studentInfo[0].createDate;
            const dateWithoutTime = createDate.split("T")[0];
            const [year, month, day] = dateWithoutTime.split("-");
            return `${day}/${month}/${year}`;
          })()
          : "",
        user.status,
        user.approve_status ? user.approve_status : "",
        user.approve_reason ? user.approve_reason : "",
      ];
    }
  };
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    Axios.get(`${apiURL}getPackage`, { headers })
      .then((res) => {
        console.log("Packages from API:", res.data);
        const transformedData = res.data.map((item) => ({
          packageId: item.id,
          packageName: item.packageName,
        }));
        setPackages(transformedData);
        console.log(transformedData);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleExcel = async () => {
    let filteredData;
    let packagesData;
    let columnPhone;
    let excelName;

    try {
      setIsDownloading(true)
      if (selectedButton === "disable") {
        const disableData = await getDisabledData()
        excelName = "ข้อมูลคนพิการ";
        columnPhone = "G";
        filteredData = disableData.filter((user) => {
          const createDate =
            user.disabledInfo &&
            user.disabledInfo[0] &&
            user.disabledInfo[0].createDate;
          const userDate = createDate ? new Date(createDate) : null;
          const withinDateRange = excelDateRange
            ? (!excelDateRange[0] || userDate >= excelDateRange[0]) &&
            (!excelDateRange[1] || userDate <= new Date(excelDateRange[1]).getTime() + 86400000)
            : true;

          const status = user.status
          const approve_status = user.approve_status
          var checkStatus = true

          if (filterStatus !== 'all') {
            if (status !== filterStatus) {
              checkStatus = false
            }
          }

          if (passStatus !== 'all') {

            if (passStatus === 'ยังไม่ระบุ' && approve_status) {
              checkStatus = false
            }

            if (passStatus !== 'ยังไม่ระบุ' && approve_status !== passStatus) {
              checkStatus = false
            }
          }


          if (!checkStatus) {
            return false
          } else {
            return withinDateRange;
          }

        });
      } else if (selectedButton === "student") {
        const studentData = await getStudentData()
        excelName = "ข้อมูลนักเรียน";
        columnPhone = "H";
        filteredData = studentData.filter((user) => {
          const createDate =
            user.studentInfo &&
            user.studentInfo[0] &&
            user.studentInfo[0].createDate;

          const userDate = createDate ? new Date(createDate) : null;
          const withinDateRange = excelDateRange
            ? (!excelDateRange[0] || userDate >= excelDateRange[0]) &&
            (!excelDateRange[1] ||
              userDate <= new Date(excelDateRange[1]).getTime() + 86400000)
            : true;

          const status = user.status
          const approve_status = user.approve_status
          var checkStatus = true

          if (filterStatus !== 'all') {
            if (status !== filterStatus) {
              checkStatus = false
            }
          }

          if (passStatus !== 'all') {

            if (passStatus === 'ยังไม่ระบุ' && approve_status) {
              checkStatus = false
            }

            if (passStatus !== 'ยังไม่ระบุ' && approve_status !== passStatus) {
              checkStatus = false
            }
          }


          if (!checkStatus) {
            return false
          } else {
            return withinDateRange;
          }
        });
      } else {
        return;
      }

      const ws1Data = [generateHeader()];
      filteredData.forEach((user) => {
        const row = generateRow(user);
        ws1Data.push(row);
      });

      const wb = XLSX.utils.book_new();
      const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);

      const w1DataLength = ws1Data.length;
      for (let i = 2; i <= w1DataLength; i++) {
        if (ws1[`${columnPhone}${i}`]) {
          ws1[`${columnPhone}${i}`].z = '@';
        }
      }
      XLSX.utils.book_append_sheet(wb, ws1, "Data");
      XLSX.writeFile(
        wb,
        `${excelName} ${new Date(excelDateRange[0]).toLocaleDateString(
          "en-GB"
        )} - ${new Date(excelDateRange[1]).toLocaleDateString("en-GB")}.xlsx`
      );
    } catch (error) {
      console.error("Error editing user:", error);
    } finally {
      setIsDownloading(false);
      setIsModalOpen(false);
    }
  };

  //Export PDF
  const componentPDF = useRef();
  const handlePDF = useReactToPrint({
    content: () => componentPDF.current,
  });
  //EXPORT PRINT
  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
  });
  const fileInputRef = useRef(null);
  const clearSearch = () => {
    setSearchValue("");
  };
  useEffect(() => {
    clearSearch();
  }, selectedButton);
  const clearDate = () => {
    setDateRange(null);
    setSearchValue("");
  };
  const [disableFile, setDisableFile] = useState([]);
  const [studentFile, setStudentFile] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  useEffect(() => {
    Axios.get(`${apiURL}upload/showDisabled`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setDisableFile(res.data.success);
      console.log(res);
    });
  }, []);
  useEffect(() => {
    Axios.get(`${apiURL}upload/showStudent`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setStudentFile(res.data.success);
      console.log(res);
    });
  }, []);
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="client-header-con">
      <div className="client-header">
        <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          จัดการข้อมูลผู้ลงทะเบียน
        </span>
        <div className="export-method">
          Export as
          <Button onClick={showModal}>Excel</Button>
          <Modal
            title="Export ข้อมูล"
            open={isModalOpen}
            onOk={handleOk}
            okText={isDownloading ? "Downloading..." : "OK"}
            okButtonProps={{ disabled: isDownloading }}
            onCancel={handleCancel}
          >
            <div>
              <span style={{ marginRight: 10 }}>วันที่ข้อมูล:</span>
              <RangePicker
                onChange={(dates, dateStrings) => setExcelDateRange(dates)}
                value={excelDateRange}
              />
            </div>

            <div style={{ marginTop: 10 }}>
              <span style={{ marginRight: 10 }}>สถานะการลงทะเบียน: </span>
              <Select onChange={(value) => setFilterStatusChange(value)} defaultValue="all">
                <Select.Option value="all">ทั้งหมด</Select.Option>
                <Select.Option value="submit">submit</Select.Option>
                <Select.Option value="approve">approve</Select.Option>
                <Select.Option value="confirm">confirm</Select.Option>
              </Select>
            </div>

            <div style={{ marginTop: 10 }}>
              <span style={{ marginRight: 10 }}>ผลการอนุมัติ: </span>
              <Select onChange={(value) => setPassStatusChange(value)} defaultValue="all" style={{ width: 100 }}>
                <Select.Option value="all">ทั้งหมด</Select.Option>
                <Select.Option value="ยังไม่ระบุ">ยังไม่ระบุ</Select.Option>
                <Select.Option value="ผ่าน">ผ่าน</Select.Option>
                <Select.Option value="ไม่ผ่าน">ไม่ผ่าน</Select.Option>
              </Select>
            </div>

          </Modal>
          <Button onClick={handlePDF}>PDF</Button>
          <Button onClick={handlePrint}>Print</Button>
        </div>
      </div>
      <div className="client-type">
        <div className="type-bt">
          <Button
            id="disable"
            onClick={() => handleButtonClick("disable")}
            type={selectedButton === "disable" ? `primary` : "default"}
            danger
            size="large"
          >
            คนพิการ
          </Button>
          <Button
            id="student"
            onClick={() => handleButtonClick("student")}
            type={selectedButton === "student" ? "primary" : "default"}
            danger
            size="large"
          >
            นักเรียน
          </Button>
        </div>
        <div className="client-tool">
          แสดงข้อมูล
          <RangePicker
            onChange={(dates, dateStrings) => setDateRange(dates)}
            value={dateRange}
          />
          <Search
            placeholder="ค้นหา"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: 200 }}
          />
          <RedoOutlined onClick={clearDate} />
        </div>
      </div>
      <div
        style={{
          minHeight: 500,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
          paddingTop: "20px",
        }}
      >
        <div
          ref={componentPDF}
          style={{ width: "100%", maxHeight: "70vh", overflowY: "auto" }}
        >
          {renderTable()}
        </div>
      </div>
    </div>
  );
};
export default ManagePage;
