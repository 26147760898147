import React, { useState, useContext } from "react";
import {
  UserOutlined,
  FileOutlined,
  TableOutlined,
  UserAddOutlined,
  FolderOpenOutlined,
  PieChartOutlined,
  LogoutOutlined,
  ImportOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import { Layout, Menu, Modal } from "antd";
import { multiStepContext } from "../StepContext.js";
import "../Styles/BackendPage.css";
import TeamPage from "./TeamPage";
import ManagePage from "./ManagePage";
import ImportPage from "./ImportPage";
import ManageUserPage from './ManageUserPage';
import Dashboard from './Dashboard';
import Logo from '../Assets/nbtc-logo.png';
import Map from '../Components/Map.js';
import Report from './Report';
import CampaignPage from "./CampaignPage.js";
import ReportUsage from "./ReportUsage.js";
import ReportDelivery from "./ReportDelivery.js";

const { Content, Footer, Sider } = Layout;

function getItem(label, key, icon, onClick) {
  return {
    key,
    icon,
    label,
    onClick,
  };
}

const BackendPage = () => {

  const handleLogout = () => {
    Modal.confirm({
      title: "Logout",
      content: "Are you sure you want to logout?",
      onOk() {
        localStorage.clear();
        setAdmin(null);
        setStep(1);
      },
    });
  };

  const items = [
    getItem("จัดการข้อมูลผู้ลงทะเบียน", "1", <UserOutlined />, () =>
      handleMenuItemClick({ key: "1" })
    ),
    getItem("จัดการข้อมูล Import", "2", <ImportOutlined />, () =>
      handleMenuItemClick({ key: "2" })
    ),
    getItem("จัดการแพ็คเกจ", "3", <FolderOpenOutlined />, () =>
      handleMenuItemClick({ key: "3" })
    ),
    getItem("รายงานข้อมูลการใช้งาน", "9", <PieChartOutlined />, () =>
      handleMenuItemClick({ key: "9" })
    ),
    getItem("ข้อมูลการจัดส่งซิมการ์ด", "10", <PieChartOutlined />, () =>
      handleMenuItemClick({ key: "10" })
    ),
    getItem("ดาวน์โหลดข้อมูลทั้งหมด", "6", <PieChartOutlined />, () =>
      handleMenuItemClick({ key: "6" })
    ),
    getItem("Logout", "7", <LogoutOutlined />, handleLogout),
  ];

  const itemsAdmin = [
    getItem("จัดการข้อมูลผู้ลงทะเบียน", "1", <UserAddOutlined />, () =>
      handleMenuItemClick({ key: "1" })
    ),
    getItem("จัดการข้อมูล Import", "2", <ImportOutlined />, () =>
      handleMenuItemClick({ key: "2" })
    ),
    getItem("จัดการแพ็คเกจ", "3", <FileOutlined />, () =>
      handleMenuItemClick({ key: "3" })
    ),
    getItem("จัดการสิทธิผู้ใช้งาน", "4", <UserAddOutlined />, () =>
      handleMenuItemClick({ key: "4" })
    ),
    getItem("สถานะการเปิดลงทะเบียน", "8", <CalendarOutlined />, () =>
      handleMenuItemClick({ key: "8" })
    ),
    getItem("แดชบอร์ด", "5", <PieChartOutlined />, () =>
      handleMenuItemClick({ key: "5" })
    ),
    getItem("รายงานข้อมูลการใช้งาน", "9", <PieChartOutlined />, () =>
      handleMenuItemClick({ key: "9" })
    ),
    getItem("ดาวน์โหลดข้อมูลทั้งหมด", "6", <PieChartOutlined />, () =>
      handleMenuItemClick({ key: "6" })
    ),
    getItem("Logout", "7", <LogoutOutlined />, handleLogout),
  ];

  const itemsStaff = [
    getItem("จัดการข้อมูลผู้ลงทะเบียน", "1", <UserAddOutlined />, () =>
      handleMenuItemClick({ key: "1" })
    ),
    getItem("จัดการข้อมูล Import", "2", <ImportOutlined />, () =>
      handleMenuItemClick({ key: "2" })
    ),
    getItem("จัดการแพ็คเกจ", "3", <FileOutlined />, () =>
      handleMenuItemClick({ key: "3" })
    ),
    getItem("แดชบอร์ด", "5", <PieChartOutlined />, () =>
      handleMenuItemClick({ key: "5" })
    ),
    getItem("Logout", "7", <LogoutOutlined />, handleLogout),
  ];

  const { admin, setStep, setAdmin } = useContext(multiStepContext);
  const isAdmin = admin?.role === "admin";
  const isStaff = admin?.role === "staff";
  const isOperator = admin?.role === "operator";
  var itemsToShow = ''
  if(admin){
    if(admin.role === "admin"){
      itemsToShow = itemsAdmin
    }else if(admin.role === "staff"){
      itemsToShow = itemsStaff
    }else{
      itemsToShow = items
    }
  }else{
    itemsToShow = items
  }

  const [collapsed, setCollapsed] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);

  console.log(admin);

  const handleMenuItemClick = (item) => {
    if (item.key === "6") {
      setIsReportModalVisible(true);
      return
    }
    setSelectedItem(item.key);

  };

  const handleReportModalCancel = () => {
    setIsReportModalVisible(false);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          {itemsToShow.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => item.onClick && item.onClick()}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Content className="layout-content" style={{ margin: "0px", paddingTop: "0px" }}>
          <div className="content-wrapper" style={{ minHeight: 100 }}>
            {selectedItem === "1" && isAdmin && <ManagePage />}
            {selectedItem === "2" && isAdmin && <ImportPage />}
            {selectedItem === "3" && isAdmin && <TeamPage />}
            {selectedItem === "4" && isAdmin && <ManageUserPage />}
            {selectedItem === "5" && isAdmin && <Dashboard />}
            {selectedItem === "8" && isAdmin && <CampaignPage />}
            {selectedItem === "9" && isAdmin && <ReportUsage />}
            {selectedItem === "1" && isStaff && <ManagePage />}
            {selectedItem === "2" && isStaff && <ImportPage />}
            {selectedItem === "3" && isStaff && <TeamPage />}
            {selectedItem === "5" && isStaff && <Dashboard />}
            {selectedItem === "1" && isOperator && <ManagePage />}
            {selectedItem === "2" && isOperator && <ImportPage />}
            {selectedItem === "3" && isOperator && <TeamPage />}
            {selectedItem === "9" && isOperator && <ReportUsage />}
            {selectedItem === "10" && isOperator && <ReportDelivery />}
          </div>
        </Content>
      </Layout>
      <Modal
        visible={isReportModalVisible}
        onCancel={handleReportModalCancel}
        footer={null}
        width={500}
      >
        <Report />
      </Modal>
    </Layout>
  );
};

export default BackendPage;
