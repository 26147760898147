import React from 'react'
import '../Styles/SidebarFooter.css'
import aisSideLogo from '../Assets/ais-logo-side.png';
import dtacSideLogo from '../Assets/dtac-logo-side.png';
import trueSideLogo from '../Assets/true-logo-side.png';
import infinityLogo from '../Assets/INFINITE_LOGO_FIT.png'

const SidebarFooter = () => {
  return (
    <div className="side-logo">
        {/* <img src={aisSideLogo}></img> */}
        <img src={infinityLogo} className='infinte-logo'></img>
        {/* <img src={dtacSideLogo}></img> */}
    </div>
  )
}

export default SidebarFooter